/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, forwardRef } from 'react';
import { CustomDatePickerWrapper, LabelAbove, Error, CalendarIcon } from './styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div onClick={onClick} ref={ref} className="custom-input">
    <input value={value || ''} placeholder={placeholder} readOnly />
    <CalendarIcon />
  </div>
));

const Datepicker = ({
  labelAbove,
  width,
  onChange,
  marginBottom,
  name,
  error,
  hasError = false,
  disableWeekendDays,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const isWeekday = date => {
    const day = date.getDay();

    if (disableWeekendDays) {
      return day !== 0 && day !== 6;
    }
    return date;
  };

  const handleDateChange = date => {
    const formatedDate = moment(date).format('YYYY/MM/DD');
    setSelectedDate(formatedDate);
    onChange(moment(formatedDate).format('YYYY-MM-DD'));
  };

  return (
    <div>
      <>
        {labelAbove && <LabelAbove>{labelAbove}</LabelAbove>}
        <CustomDatePickerWrapper width={width} marginBottom={marginBottom} hasError={hasError}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            filterDate={isWeekday}
            placeholderText="yyyy-mm-dd"
            name={name}
            customInput={<CustomInput placeholder="yyyy-mm-dd" />}
            {...rest}
          />
        </CustomDatePickerWrapper>
      </>

      {error && <Error>{error}</Error>}
    </div>
  );
};

export default Datepicker;
