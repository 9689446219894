import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './modules';

const AppContainer = () => (
  <>
    <App />
  </>
);

function renderApp() {
  const container = document.getElementById('app');
  if (container) {
    const root = createRoot(container);
    root.render(<AppContainer />);
    return true; // Indicate that the app has been rendered
  }
  return false; // Indicate that the app has not been rendered
}

function checkForAppDiv() {
  if (!renderApp()) {
    setTimeout(checkForAppDiv, 100); // Retry after 100ms if the div is not found
  }
}

// Start checking for the 'app' div
checkForAppDiv();
