import React from 'react';
import { styled } from 'styled-components';
import { Field } from 'form';

import Select from './Select';
import Input from './Input';
import Textarea from './Textarea';
import Datepicker from './Datepicker';

const FieldContainer = styled.div`
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '15px')};
  width: 100%;
  ${({ labelBeforeInput }) => {
    if (labelBeforeInput) {
      return {
        display: 'flex',
        alignItems: 'center'
      };
    }
  }};
`;

const Label = styled.div`
  margin-bottom: 15px;
  ${({ marginRight }) => {
    if (marginRight) {
      return { marginRight: '10px' };
    } else {
      return {
        marginLeft: '10px'
      };
    }
  }};
`;

const typeToComponent = {
  select: Select,
  input: Input,
  textarea: Textarea,
  datepicker: Datepicker
};

const defaulComponent = () => <span>wooot</span>;

const FormHelper = ({ fields, namePrefix = '' }) => (
  <>
    {fields.map(
      ({ component, labelBeforeInput, labelAfterInput, noMargin = false, name, ...props }) => (
        <FieldContainer key={name} noMargin={noMargin} labelBeforeInput={labelBeforeInput}>
          <Label marginRight={true}>{labelBeforeInput}</Label>
          <Field
            {...props}
            name={`${namePrefix}${name}`}
            render={typeToComponent[component] || defaulComponent}
          />
          <Label marginRight={false}>{labelAfterInput}</Label>
        </FieldContainer>
      )
    )}
  </>
);

export default FormHelper;
