import React from 'react';

import { NotaAvailableDateContainer, QuitContainer, Title, Description, Image } from './styles';
import { quit } from '_src/assets/images';

const NoAvailabilityModal = ({ onClick, notAvailableModalContent }) => {
  return (
    <NotaAvailableDateContainer>
      <QuitContainer onClick={onClick}>
        <Image src={quit} alt="closeNotAvailableDate" style={{ height: '20px', width: '20px' }} />
      </QuitContainer>

      <Title>{notAvailableModalContent.header}</Title>

      <Description>{notAvailableModalContent.description}</Description>

      <Description>(877) 350-0884</Description>
    </NotaAvailableDateContainer>
  );
};

export { NoAvailabilityModal };
