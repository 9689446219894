import { styled } from 'styled-components';
import ModalBody from '_src/components/ModalBody';

export const ModalContainer = styled(ModalBody)`
  padding-top: 30px;
`;

export const PageContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const TextHelper = styled.div`
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
`;
