import React, { useContext } from 'react';
import { Context } from 'store';

import { RoutesConfig } from '_src/modules/Router';
import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { Button } from '_src/components/ModalButton';
import { checkmark, no } from '_src/assets/images';
import { PageContainer } from './styles';

const DriversLicense = () => {
  const { getData, setData } = useContext(Context);
  const { navigateTo } = getData();

  return (
    <>
      <ModalHeading title="Drivers License" subtitle="Do you have a current Driver’s license?" />

      <ModalBody>
        <PageContainer>
          <Button
            size="big"
            onClick={() => navigateTo(RoutesConfig.DriversLicenseForm)}
            imageProps={{ uri: checkmark, label: 'YES' }}
          />

          <Button
            size="big"
            onClick={() => {
              setData(
                'finalScreenMessage',
                'Unfortunately, without a driver’s license, you cannot start your CDL training.  Call us directly at 877-350-0884 if you have additional questions.'
              );
              navigateTo(RoutesConfig.FinalStep);
            }}
            imageProps={{ uri: no, label: 'NO' }}
          />
        </PageContainer>
      </ModalBody>
    </>
  );
};

export default DriversLicense;
