import { styled } from 'styled-components';

export const ModalTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  color: #f57b20;
  font-size: 22px;
`;

export const Subtitle = styled.div`
  padding-right: 32px;
  color: #000;
  font-size: 18px;
  white-space: pre-line;
  line-height: 30px;
`;
