import React, { useEffect, useRef, useState, useContext } from 'react';
import { Context } from 'store';

import ReactDOM from 'react-dom';
import { quit } from '_src/assets/images';
import { Overlay, Container, Close, Image } from './styles';
import ConfirmDialog from './ConfirmDialog/confirmDialog';

const Modal = ({ visible = false, onClose = () => {}, children, overlayStyle }) => {
  const { getData } = useContext(Context);
  const activeRoute = getData('activeRoute');

  const modalContainer = document.getElementById('modal-container');
  const overlay = useRef();
  const container = useRef();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [backButtonTriggered, setBackButtonTriggered] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handlePopState = event => {
      event.preventDefault();
      setBackButtonTriggered(true);
      window.history.pushState(null, document.title, window.location.href);
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      setTimeout(() => {
        if (modalContainer && !modalContainer.hasChildNodes()) {
          document.body.style.overflow = 'scroll';
        }
      }, 100);
    };
  }, [modalContainer]);

  const handleCloseClick = () => {
    if (activeRoute === 'final-step' || activeRoute === 'appointment-details') {
      setConfirmVisible(false);
      setBackButtonTriggered(false);
      onClose();
    } else {
      setConfirmVisible(true);
    }
  };

  const handleConfirm = () => {
    setConfirmVisible(false);
    setBackButtonTriggered(false);
    onClose();
  };

  const handleCancel = () => {
    setConfirmVisible(false);
    setBackButtonTriggered(false);
  };

  useEffect(() => {
    if (backButtonTriggered) {
      setConfirmVisible(true);
    }
  }, [backButtonTriggered]);

  return (
    visible &&
    ReactDOM.createPortal(
      <>
        <Overlay ref={overlay}>
          <Container ref={container} style={overlayStyle}>
            {children}
            <Close data-cy="closeApplicant" onClick={handleCloseClick}>
              <Image src={quit} alt="close" />
            </Close>
          </Container>
        </Overlay>
        <ConfirmDialog visible={confirmVisible} onConfirm={handleConfirm} onCancel={handleCancel} />
      </>,
      modalContainer
    )
  );
};

export default Modal;
