export const createContactConfig = { path: 'contact/create', method: 'post' };

export const updateContactConfig = { path: 'contact/update/:id', method: 'patch' };

export const getLocationsConfig = { path: 'account_data/all', method: 'get', defaultValue: [] };

export const getBusyEventsConfig = {
  path: 'event/get_salesforce_events',
  method: 'get',
  defaultValue: []
};

export const createEventConfig = { path: 'event/create', method: 'post' };
