import React, { useContext, useEffect } from 'react';
import { Context } from 'store';

import PreScreen from '_src/modules/PreScreen';
import VerifyLocation from '_src/modules/VerifyLocation';
import DriversLicense from '_src/modules/DriversLicense';
import DriversLicenseForm from '_src/modules/DriversLicenseForm';
import Age from '_src/modules/Age';
import Congratulations from '_src/modules/Congratulations';
import ScheduleVisit from '_src/modules/ScheduleVisit';
import ScheduleVisitForm from '_src/modules/ScheduleVisitForm';
import AppointmentDetails from '_src/modules/AppointmentDetails';
import FinalStep from '_src/modules/FinalStep';
import ContactUs from '_src/modules/ContactUs';

const RoutesConfig = {
  PreScreen: 'pre-screen',
  VerifyLocation: 'verify-location',
  DriversLicense: 'drivers-license',
  DriversLicenseForm: 'drivers-license-form',
  Age: 'age',
  Congratulations: 'congratulations',
  ScheduleVisit: 'schedule-visit',
  ScheduleVisitForm: 'schedule-visit-form',
  AppointmentDetails: 'appointment-details',
  FinalStep: 'final-step',
  ContactUs: 'contact-us'
};

const Router = () => {
  const { getData, setData } = useContext(Context);
  const activeRoute = getData('activeRoute');

  /**
   * Configure `navigateTo` helper method
   */
  useEffect(() => {
    setData('navigateTo', routeKey => {
      setData('activeRoute', routeKey);
    });
  }, []);

  switch (activeRoute) {
    case RoutesConfig.PreScreen:
      return <PreScreen />;
    case RoutesConfig.VerifyLocation:
      return <VerifyLocation />;
    case RoutesConfig.DriversLicense:
      return <DriversLicense />;
    case RoutesConfig.DriversLicenseForm:
      return <DriversLicenseForm />;
    case RoutesConfig.Age:
      return <Age />;
    case RoutesConfig.Congratulations:
      return <Congratulations />;
    case RoutesConfig.ScheduleVisit:
      return <ScheduleVisit />;
    case RoutesConfig.ScheduleVisitForm:
      return <ScheduleVisitForm />;
    case RoutesConfig.AppointmentDetails:
      return <AppointmentDetails />;
    case RoutesConfig.FinalStep:
      return <FinalStep />;
    case RoutesConfig.ContactUs:
      return <ContactUs />;
    default:
      return <></>;
  }
};

export { RoutesConfig };
export default Router;
