import moment from 'moment';

export default [
  {
    name: 'branchLocation',
    component: 'select',
    labelAbove: 'Please select the branch location',
    label: 'Please select the branch location',
    isScheduleVisitSelect: true
  },
  {
    name: 'meetingDate',
    component: 'datepicker',
    type: 'date',
    minDate: moment().format('MM-DD-YYYY'),
    selectUntil: moment().add(22, 'days'),
    labelAbove: 'What day would you like to meet?',
    disableWeekendDays: true
  }
];
