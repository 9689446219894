import React from 'react';

import { ModalContent, ErrorContainer } from './styles';

const ModalBody = ({ children, error }) => {
  return (
    <ModalContent>
      {error && <ErrorContainer>{error}</ErrorContainer>}
      {children}
    </ModalContent>
  );
};

export default ModalBody;
