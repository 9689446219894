import styled from 'styled-components';
import { calendar } from '_src/assets/images';

const getColor = props => {
  return props.hasError ? '#FF7B5C' : '#707070';
};

const CustomDatePickerWrapper = styled.div`
  .custom-input {
    display: flex;
    align-items: center;
    border: 1px solid ${props => getColor(props)};
    border-radius: 10px;
    padding: 5px;
    width: ${props => (props.width ? props.width : '100%')};
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '20px')};

    input {
      border: none;
      outline: none;
      flex-grow: 1;
      font-size: 15px;
      color: grey;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .custom-input input::-webkit-calendar-picker-indicator {
    opacity: 0.5;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const CalendarIcon = styled.div`
  background-image: url(${calendar});
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
`;

const Label = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 10px;
  position: absolute;
  left: 20px;
  top: ${({ hasValue }) => (hasValue ? 5 : 18)}px;
  opacity: ${({ hasValue }) => (hasValue ? 1 : 0)};
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
`;

const LabelAbove = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 16px;
  margin-bottom: 10px;
`;

const Error = styled.div`
  color: #f57b20;
  font-size: 10px;
  margin-top: -15px;
`;

export { CustomDatePickerWrapper, Label, LabelAbove, Error, CalendarIcon };
