import React, { useContext, useRef, useEffect, useState } from 'react';

import moment from 'moment';
import { Context, useFetch, useSave } from 'store';
import { Form } from 'form';

import FormHelper from '_src/components/FormHelper';
import config from '_src/services/scheduleVisit';
import {
  getLocationsConfig,
  getBusyEventsConfig,
  createEventConfig,
  updateContactConfig
} from '_src/services/apiConfig';
import { RoutesConfig } from '_src/modules/Router';

import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';

import { zipDetails } from '_src/utils/zipHelpers';

import { PageContainer, SlotsContainer } from './styles';

import { calculateFreeSlots } from './freeSlots';
import { durationMap } from './constants';

import { EmptyStateData } from './emptyState';
import { ModalButton } from './button';
import { NoAvailabilityModal } from './noAvailabilityModal';
import { FreeSlotsSection } from './freeSlotsSection';

const noAvailabilityText = {
  branch: {
    header: 'Unfortunately, this location does not allow online scheduling.',
    description: 'Please give us a call to schedule your appointment or select another branch.'
  },
  date: {
    header: 'Need a date further out?',
    description: 'Give us a call and we can help.'
  }
};

const ScheduleVisit = () => {
  const formApi = useRef(null);
  const { getData, setData } = useContext(Context);
  const { navigateTo, contact } = getData();

  const { get: getLocations } = useFetch(getLocationsConfig);
  const { get: getBusyEvents } = useFetch(getBusyEventsConfig);
  const { save: createNewEvent } = useSave(createEventConfig);
  const { save: updateContact } = useSave(updateContactConfig);

  const [availableHours, setAvailableHours] = useState([]);
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableDatePicker, setDisableDatePicker] = useState(true);

  const [notAvailableModal, setNotAvailableModal] = useState(false);
  const [notAvailableModalContent, setNotAvailableModalContent] = useState({
    header: '',
    description: ''
  });

  const formIsValid = Boolean(formApi?.current?.validate());
  const { meetingDate } = formApi?.current?.values() || {};

  const { id, accountsZip } = contact || {};
  const zipCodeDetails = zipDetails(accountsZip);
  const { longitude, latitude } = zipCodeDetails;

  useEffect(() => {
    const getAllBranchesData = async () => {
      const params = { accounts_zip: accountsZip, contact_lat: latitude, contact_lng: longitude };
      const allLocations = await getLocations({ params });

      const locations = allLocations[0].data.accounts;

      const formatedLocations = locations.map(item => {
        const { zip, street, city, state, id } = item;

        return {
          label: `${street}, ${city} ${state} ${zip}`,
          value: `${id}`,
          ...item
        };
      });

      setLocations(formatedLocations);
    };

    getAllBranchesData();
  }, []);

  const getBusyEventsAndCalculateFreeSlots = async date => {
    setLoading(true);

    const selectedBranchData = locations.find(({ id }) => {
      const branchLocation = formApi?.current?.values()?.branchLocation;
      return id === Number(branchLocation);
    });

    const duration = durationMap[selectedBranchData.appointmentDuration];
    const activityEnd = moment(date).add(10, 'days');

    const params = new URLSearchParams({
      requestType: 'getEvents',
      ActivityDate: moment(date).format('YYYY-MM-DD'),
      EndDate: moment(activityEnd).format('YYYY-MM-DD'),
      AccountId: selectedBranchData.crmId
    });

    const busyEvents = await getBusyEvents({ params: params });

    const freeSlots = calculateFreeSlots({
      from: moment(date).format('YYYY-MM-DD'),
      to: moment(activityEnd).format('YYYY-MM-DD'),
      duration,
      businessHours: {
        opening: { hour: '08', minute: '00' },
        closing: { hour: '16', minute: '30' },
        branchTimeZone: selectedBranchData.timeZone
      },
      busySlots: busyEvents[0].data.events
    });

    setAvailableHours(freeSlots);
    setLoading(false);
  };

  const updateContactData = async meetingType => {
    const selectedBranchData = locations.find(({ id }) => {
      const branchLocation = formApi?.current?.values()?.branchLocation;
      return id === Number(branchLocation);
    });

    const params = {
      id: contact.id,
      accountId: selectedBranchData.id,
      ownerId: selectedBranchData.ownerId
    };
    const update = await updateContact(params);

    setData('contact', {
      ...contact,
      ...update.data.contact,
      appointmenType: meetingType
    });
    setData('selectedBranch', selectedBranchData);
  };

  const createEvent = async (appointmentDate, groupSitId, meetingType) => {
    if (formIsValid) {
      setError(null);
      setLoading(true);

      const selectedBranchData = locations.find(({ id }) => {
        const branchLocation = formApi?.current?.values()?.branchLocation;
        return id === Number(branchLocation);
      });

      const newEventPayload = {
        contactId: id,
        appointmentType: meetingType,
        appointmentDate: moment(appointmentDate).toISOString(),
        localTime: moment.utc(appointmentDate).local().format('MM-DD-YYYY HH:mm'),
        group_sit: Boolean(groupSitId),
        master_event_id: groupSitId ?? null,
        public_calendar: selectedBranchData.publicCalendar
      };
      await updateContactData(meetingType);

      const newEvent = await createNewEvent(newEventPayload);

      if (newEvent.code === 429) {
        setLoading(false);
        setError('This slot is already booked. Please chosse another slot.');
        return;
      }

      if (newEvent.code !== 201) {
        setLoading(false);
        setError('Something went wrong!');
        return;
      }

      setData('event', { ...newEvent.data.event, location: selectedBranchData });
      navigateTo(RoutesConfig.AppointmentDetails);
      setLoading(false);
    }
  };

  const validateBranchAndDate = ev => {
    const value = formApi?.current?.values();

    const selectedDateIsBeyondLimit = moment(value?.meetingDate).isAfter(moment().add(22, 'days'));

    const currentBranch = locations.find(({ id }) => {
      return id === Number(value?.branchLocation);
    });

    const { active, phoneSit, personSit, groupSit } = currentBranch || {};
    const branchWithoutAppointment = !active || (!phoneSit && !personSit && !groupSit);

    if (selectedDateIsBeyondLimit) {
      setNotAvailableModalContent({
        header: noAvailabilityText.date.header,
        description: noAvailabilityText.date.description
      });
      setNotAvailableModal(true);
      setAvailableHours([]);
      ev.resetField('meetingDate');
      return false;
    }

    if (branchWithoutAppointment) {
      setNotAvailableModalContent({
        header: noAvailabilityText.branch.header,
        description: noAvailabilityText.branch.description
      });
      setNotAvailableModal(true);
      setAvailableHours([]);
      setDisableDatePicker(true);
      ev.resetField('branchLocation');
      return false;
    }

    setDisableDatePicker(false);
    return true;
  };

  const handleDateChange = (date, ev) => {
    const validate = validateBranchAndDate(ev);

    if (!validate) {
      return;
    }

    getBusyEventsAndCalculateFreeSlots(date);
  };

  const handleLocationChange = async (branchID, ev) => {
    const validate = validateBranchAndDate(ev);

    if (!validate) {
      return;
    }

    if (meetingDate?.trim()?.length) {
      getBusyEventsAndCalculateFreeSlots(meetingDate);
    }
  };

  const formFields = [
    {
      ...config.form.fields[0],
      items: locations,
      onChange: handleLocationChange
    },
    {
      ...config.form.fields[1],
      onChange: handleDateChange,
      min: new window.Date().toISOString().split('T')[0],
      disabled: disableDatePicker
    }
  ];

  return (
    <>
      <ModalHeading title="Schedule Your Appointment" />

      <ModalBody error={error}>
        <PageContainer>
          <Form formApi={form => (formApi.current = form)} schema={config.form.schema}>
            <FormHelper fields={formFields} />
          </Form>

          <EmptyStateData meetingDate={meetingDate} />

          <SlotsContainer isEmpty={Object.keys(availableHours).length === 0}>
            <FreeSlotsSection
              availableHours={availableHours}
              loading={loading}
              createEvent={createEvent}
              selectedBranchData={locations.find(({ id }) => {
                const branchLocation = formApi?.current?.values()?.branchLocation;
                return id === Number(branchLocation);
              })}
            />
          </SlotsContainer>

          {Object.keys(availableHours).length > 0 && (
            <ModalButton
              onClick={() => {
                setData(
                  'finalScreenMessage',
                  'Thank you for submitting your information, one of our representatives will call you soon about starting your new career in trucking!'
                );
                navigateTo(RoutesConfig.FinalStep);
              }}
              loading={loading}
            />
          )}
        </PageContainer>
      </ModalBody>

      {notAvailableModal && (
        <NoAvailabilityModal
          onClick={() => setNotAvailableModal(false)}
          notAvailableModalContent={notAvailableModalContent}
        />
      )}
    </>
  );
};

export default ScheduleVisit;
