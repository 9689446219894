const createPersistentRepo = () => {
  const id = process.env.NODE_ENV === 'production' ? 'widget' : 'widget:dev';

  const globalState = localStorage;

  const encrypt = string => '=' + btoa(string);
  const decrypt = string => atob(string.substring(1));

  const resetState = () => {
    const timestamp = new Date().toISOString();
    setState({ timestamp });
  };

  const getState = () => {
    if (!globalState.getItem(id)) {
      resetState();
    }

    return JSON.parse(decrypt(globalState.getItem(id)));
  };

  const setState = state => globalState.setItem(id, encrypt(JSON.stringify(state)));

  if (!globalState.getItem(id)) {
    const timestamp = new Date().toISOString();
    setState({ timestamp });
  }

  return {
    set: (key, val) => {
      const state = getState();
      state[key] = val;
      setState(state);
    },

    get: (key, defaultValue) => getState()[key] || defaultValue,

    delete: key => {
      const state = getState();
      delete state[key];
      setState(state);
    },

    clear: () => {
      globalState.clear(id);
    },

    all: () => getState()
  };
};

const PersistentRepo = createPersistentRepo();

if (process.env.NODE_ENV === 'development') {
  window.PersistentRepo = PersistentRepo;
}

export { PersistentRepo };
