import zipcodes from 'zipcodes';
import moment from 'moment-timezone';

const zipDetails = zip => {
  const location = zipcodes.lookup(zip);

  if (!location) {
    return null;
  }

  return location;
};

const getTimezoneByZip = zip => {
  const details = zipDetails(zip);

  if (!details) {
    return null;
  }

  const { latitude, longitude } = details;
  const timezone = moment.tz.guess({ lat: latitude, lon: longitude });

  return timezone;
};

export { getTimezoneByZip, zipDetails };
