import { styled } from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextHelper = styled.div`
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const Image = styled.img`
  height: 200px;
  width: 200px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;

  gap: 20px;
  margin-top: 20px;
`;
