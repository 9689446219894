import moment from 'moment';
export default [
  {
    name: 'issueState',
    component: 'select',
    labelAbove: 'Issue State',
    items: GLOBALS.states,
    type: 'number',
    label: 'Please select state'
  },
  {
    name: 'licenseIssuedDate',
    component: 'datepicker',
    type: 'date',
    labelAbove: 'Issue Date',
    maxDate: moment().format('YYYY-MM-DD')
  },
  {
    name: 'licenseExpirationDate',
    component: 'datepicker',
    type: 'date',
    labelAbove: 'Expire Date',
    minDate: moment().add(1, 'day').format('YYYY-MM-DD')
  }
];
