/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, forwardRef } from 'react';
import { CustomDatePickerWrapper, LabelAbove, Error, CalendarIcon } from './styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div onClick={onClick} ref={ref} className="custom-input">
    <input value={value || ''} placeholder={placeholder} readOnly />
    <CalendarIcon />
  </div>
));

const Datepicker = ({
  labelAbove,
  width,
  onChange,
  marginBottom,
  name,
  error,
  hasError = false,
  disableWeekendDays,
  maxDate,    
  selectUntil, 
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const isWeekday = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleDateChange = date => {
    const formattedDate = moment(date).format('YYYY/MM/DD');
    setSelectedDate(formattedDate);
    onChange(moment(formattedDate).format('YYYY-MM-DD'));
  };

  const dayClassName = date => {
    const isWeekdayDate = isWeekday(date);
    if (selectUntil && isWeekdayDate && moment(date).isAfter(moment(selectUntil))) {
      return 'dotted-border';
    }
    return '';
  };

  const filterDate = date => {
    const isWeekdayDate = isWeekday(date);
    const isBeforeMaxDate = maxDate ? moment(date).isSameOrBefore(moment(maxDate), 'day') : true;

    if (disableWeekendDays && !isWeekdayDate) return false;

    return isBeforeMaxDate;
  };

  return (
    <div>
      {labelAbove && <LabelAbove>{labelAbove}</LabelAbove>}
      <CustomDatePickerWrapper width={width} marginBottom={marginBottom} hasError={hasError}>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          filterDate={filterDate}     
          placeholderText="yyyy-mm-dd"
          name={name}
          dayClassName={dayClassName} 
          customInput={<CustomInput placeholder="yyyy-mm-dd" />}
          {...rest}
        />
      </CustomDatePickerWrapper>

      {error && <Error>{error}</Error>}
    </div>
  );
};

export default Datepicker;
