import React, { useRef } from 'react';
import { InputContainer, Container, Label, Prefix, Postfix, LabelAbove } from './styles';

export default ({
  value = '',
  error,
  onChange,
  onBlur = () => {},
  label = '',
  prefix = '',
  postfix = '',
  dataCy = '',
  labelAbove = '',
  placeholder = '',
  disabled = false,
  marginBottom,
  ...rest
}) => {
  const hasValue = !!value;
  const hasPrefix = !!prefix.length;
  const hasPostfix = !!postfix.length;
  const hasLabel = label.length;
  const input = useRef();

  return (
    <div>
      {labelAbove && <LabelAbove>{labelAbove}</LabelAbove>}

      <Container
        onClick={() => input.current.focus()}
        {...(marginBottom ? { marginBottom: { marginBottom } } : undefined)}
      >
        {!labelAbove && <Label hasValue={hasValue}>{label}</Label>}

        <InputContainer
          disabled={disabled}
          ref={input}
          hasValue={hasValue}
          error={error}
          hasLabel={hasLabel}
          data-cy={dataCy}
          hasPrefix={hasPrefix}
          {...rest}
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          onBlur={e => onBlur(e)}
        />

        <Label error={error}>{error}</Label>

        {hasPrefix && (
          <Prefix hasValue={hasValue} error={error} hasLabel={hasLabel}>
            {prefix}
          </Prefix>
        )}

        {hasPostfix && (
          <Postfix hasValue={hasValue} error={error} hasLabel={hasLabel}>
            {postfix}
          </Postfix>
        )}
      </Container>
    </div>
  );
};
