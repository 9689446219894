import { styled } from 'styled-components';

const getColor = ({ hasError }, attribute) => {
  if (hasError) {
    return '#FF7B5C';
  }

  if (attribute === 'border') {
    return '#707070';
  }

  return '#F57B20';
};

const getFocusColor = ({ hasError }) => {
  return hasError ? '#FC4318' : '#1f1f1fa3';
};

const Prefix = styled.label`
  color: ${getColor};
  font-size: 14px;
  position: absolute;
  left: 20px;
  top: ${({ hasValue, hasLabel }) => (hasValue && hasLabel ? 19 : 14)}px;
`;

const Postfix = styled(Prefix)`
  left: unset;
  right: 20px;
`;

const InputContainer = styled.input`
  border: 1px solid ${props => (props.hasError ? '#FF7B5C' : '#707070')};
  border-radius: 10px;
  width: ${props => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  color: grey;
  font-size: 14px;
  padding: ${props => (props.padding ? props.padding : '10px')};

  ${({ hasPrefix }) => hasPrefix && 'padding-left: 28px;'} ::placeholder {
    color: lightgrey;
  }

  :disabled {
    background-color: #ddd;
  }

  :hover,
  :focus {
    outline: none;
    border-color: ${getFocusColor};
    color: grey;
  }
`;

const Container = styled.div`
  position: relative;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '20px')};
`;

const Label = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 10px;
  position: absolute;
  left: 20px;
  margin-top: 2px;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
`;

const LabelAbove = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 16px;
  margin-bottom: 10px;
`;

export { InputContainer, Container, Label, Prefix, LabelAbove, Postfix };
