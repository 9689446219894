const yup = require('yup');
const moment = require('moment');

const schema = yup.object().shape({
  branchLocation: yup.string().nonNullable().required('Branch Location is required!'),
  meetingDate: yup
    .string()
    .nonNullable()
    .required('Meeting Date is required')
    .test('is-valid-date', 'Meeting Date must be a valid date', function (value) {
      return moment(value, 'YYYY-MM-DD', true).isValid();
    })
    .test('is-equal-or-after-today', 'Meeting Date must be today or after today', function (value) {
      return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day');
    })
});

module.exports = schema;
