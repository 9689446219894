import React, { useContext, useState } from 'react';
import { Context, useSave } from 'store';

import { RoutesConfig } from '_src/modules/Router';
import ModalHeading from '_src/components/ModalHeading';
import { Button } from '_src/components/ModalButton';
import { checkmark, no } from '_src/assets/images';
import { updateContactConfig } from '_src/services/apiConfig';
import { ModalContainer, PageContainer, TextHelper } from './styles';

const Age = () => {
  const { getData, setData } = useContext(Context);
  const { save: updateContact } = useSave(updateContactConfig);
  const { navigateTo, contact } = getData();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleNext = async (value, nextRoute) => {
    setLoading(true);
    setError(null);

    await updateContact({
      id: contact.id,
      correctAge: value
    })
      .then(response => {
        if (response.code !== 204) {
          setError('Something went wrong!');
          return;
        }
        setLoading(false);
        setData('contact', { ...contact, ...response.data.contact });
        setData(
          'finalScreenMessage',
          'Unfortunately, you must be at least 18 years of age to attend CDL training.  Please call us at 877-350-0884 if you have additional questions.'
        );
        navigateTo(nextRoute);
      })
      .catch(() => {
        setLoading(false);
        setError('Updating contact failed !');
      });
  };

  return (
    <>
      <ModalHeading title="Age" subtitle="Are you 18 or older ?" />

      <ModalContainer error={error}>
        <PageContainer>
          <Button
            loading={loading}
            size="big"
            onClick={() => handleNext(true, RoutesConfig.Congratulations)}
            imageProps={{ uri: checkmark, label: 'YES' }}
          />

          <Button
            loading={loading}
            size="big"
            onClick={() => {
              handleNext(false, RoutesConfig.FinalStep);
            }}
            imageProps={{ uri: no, label: 'NO' }}
          />
        </PageContainer>

        <TextHelper>(Please select one)</TextHelper>
      </ModalContainer>
    </>
  );
};

export default Age;
