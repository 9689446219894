let yup = require('yup');

const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

export default yup.object().shape({
  zipCode: yup
    .string()
    .nonNullable()
    .required('Zip code is required!')
    .matches(zipCodeRegex, 'Zip code is not valid!')
});
