import React, { useRef, useContext, useState, useEffect } from 'react';
import { Context, useSave } from 'store';
import { Form } from 'form';

import config from '_src/services/verifyLocation';
import { RoutesConfig } from '_src/modules/Router';
import FormHelper from '_src/components/FormHelper';
import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { Button } from '_src/components/ModalButton';
import { updateContactConfig } from '_src/services/apiConfig';
import { zipDetails, getTimezoneByZip } from '_src/utils/zipHelpers';
import { PageContainer, FormContainer } from './styles';

const VerifyLocation = () => {
  const formApi = useRef(null);
  const { getData, setData } = useContext(Context);
  const { navigateTo, contact } = getData();

  const { save: updateContact } = useSave(updateContactConfig);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    return formApi?.current?.setField('zipCode', contact?.zip);
  }, []);

  const handleSubmit = async () => {
    const formIsValid = formApi.current.validate();

    if (formIsValid) {
      setLoading(true);
      setError(null);
      const values = formApi.current.values();

      const zipCodeDetails = zipDetails(values.zipCode);
      const timeZoneByZip = getTimezoneByZip(values.zipCode);

      try {
        if (!zipCodeDetails) {
          setError('Invalid ZIP code');
          return;
        }

        if (!timeZoneByZip) {
          return setError(
            "There aren't any branches near your current location. Please enter a valid zip code for the nearest branch."
          );
        }

        const { longitude, latitude, state, city, zip } = zipCodeDetails;

        const response = await updateContact({
          ...values,
          id: contact.id,
          lng: `${longitude}`,
          lat: `${latitude}`,
          state: state,
          city: city,
          zip: zip,
          contactTimeZone: timeZoneByZip
        });

        if (response.code !== 204) {
          setError('Something went wrong!');
          return;
        }

        if (response.data.contact.defaultBranch) {
          navigateTo(RoutesConfig.FinalStep);
          return;
        }

        if (response.data.contact.accountId) {
          setData('contact', { ...contact, ...response.data.contact });
          navigateTo(RoutesConfig.DriversLicense);
        } else {
          setError(
            "There aren't any branches near your current location. Please enter a valid zip code for the nearest branch."
          );
          //navigateTo(RoutesConfig.FinalStep);
        }
      } catch (err) {
        setLoading(false);
        setError('Something went wrong!');
        console.log(err);
      }
    }
  };

  return (
    <>
      <ModalHeading
        title="Verify Location"
        subtitle={
          'Please enter the zip code for your current residence: \n (We use this information to find the school closest to you)'
        }
      />

      <ModalBody error={error}>
        <PageContainer>
          <FormContainer>
            <Form formApi={form => (formApi.current = form)} schema={config.form.schema}>
              <FormHelper fields={[{ ...config.form.fields[0], defaultValue: contact?.zip }]} />
            </Form>

            <Button
              label="Submit"
              width="100%"
              onClick={handleSubmit}
              loading={loading && !error}
            />
          </FormContainer>
        </PageContainer>
      </ModalBody>
    </>
  );
};

export default VerifyLocation;
