import moment from 'moment';

const number = moment().format('ss');
let randomLetter = (Math.random() + 1).toString(36).substring(7);

export const MOCK_CONTACT = {
  // first_name: `Al${number}${randomLetter}test`,
  // last_name: `Sos${number}${randomLetter}sdsd`,
  // email: `ssssssass${number}${randomLetter}s@example.com`,
  // phone: `3559220${number}e9dd`,
  first_name: 'Dashi',
  last_name: 'Duraj',
  email: 'duraj@gmail.com',
  phone: '123456789',
  zip: '60105',
  sent_to_sf: false,
  create_in_sf: false,
  state: 'IL'
};
//46227
export const MOCK_BUSY_EVENTS = [
  {
    ActivityDate: '2024-05-24',
    ActivityDateTime: '2024-05-24T15:00:00.000+0000',
    CreatedById: '0053w000008xIB3AAM',
    DurationInMinutes: 60,
    EndDateTime: '2024-05-24T16:00:00.000+0000',
    EventSubtype: 'Event',
    Id: '00U3w00001GH3ckEAD',
    IsAllDayEvent: false,
    IsPrivate: false,
    IsReminderSet: false,
    LastModifiedById: '0053w000008xIB3AAM',
    OwnerId: '0053w000008xIB3AAM',
    ShowAs: 'Busy',
    StartDateTime: '2024-05-24T15:00:00.000+0000',
    Subject: 'Class Prep',
    Type: 'Other',
    attributes: {
      type: 'Event',
      url: '/services/data/v61.0/sobjects/Event/00U3w00001GH3ckEAD'
    }
  },
  {
    ActivityDate: '2024-05-24',
    ActivityDateTime: '2024-05-24T16:00:00.000+0000',
    CreatedById: '0053w000008xIB3AAM',
    Description: 'Group Sit - MAX 6',
    DurationInMinutes: 60,
    EndDateTime: '2024-05-24T17:00:00.000+0000',
    EventSubtype: 'Event',
    Id: '00U3w00001GHA4WEAX',
    IsAllDayEvent: false,
    IsPrivate: false,
    IsReminderSet: false,
    LastModifiedById: '0053w000008xIB3AAM',
    Location: 'Sacramento',
    OwnerId: '0053w000008xIB3AAM',
    ShowAs: 'Busy',
    StartDateTime: '2024-05-24T16:00:00.000+0000',
    Subject: 'Group Sit - MAX 6',
    Type: 'Meeting',
    attributes: {
      type: 'Event',
      url: '/services/data/v61.0/sobjects/Event/00U3w00001GHA4WEAX'
    }
  },
  {
    ActivityDate: '2024-05-24',
    ActivityDateTime: '2024-05-24T18:00:00.000+0000',
    CreatedById: '0053w000008xIB3AAM',
    Description: 'Group Sit - MAX 6',
    DurationInMinutes: 60,
    EndDateTime: '2024-05-24T19:00:00.000+0000',
    EventSubtype: 'Event',
    Id: '00U3w00001GHATGEA5',
    IsAllDayEvent: false,
    IsPrivate: false,
    IsReminderSet: false,
    LastModifiedById: '0053w000008xIB3AAM',
    Location: 'Sacramento',
    OwnerId: '0053w000008xIB3AAM',
    ShowAs: 'Busy',
    StartDateTime: '2024-05-24T18:00:00.000+0000',
    Subject: 'Group Sit - MAX 6',
    Type: 'Meeting',
    attributes: {
      type: 'Event',
      url: '/services/data/v61.0/sobjects/Event/00U3w00001GHATGEA5'
    }
  },
  {
    ActivityDate: '2024-05-24',
    ActivityDateTime: '2024-05-24T19:00:00.000+0000',
    CreatedById: '0053w000008xIB3AAM',
    DurationInMinutes: 30,
    EndDateTime: '2024-05-24T19:30:00.000+0000',
    EventSubtype: 'Event',
    Id: '00U3w00001GHJQVEA5',
    IsAllDayEvent: false,
    IsPrivate: false,
    IsReminderSet: false,
    LastModifiedById: '0053w000008xIB3AAM',
    OwnerId: '0053w000008xIB3AAM',
    ShowAs: 'Busy',
    StartDateTime: '2024-05-24T19:00:00.000+0000',
    Subject: 'Lunch',
    Type: 'Other',
    attributes: {
      type: 'Event',
      url: '/services/data/v61.0/sobjects/Event/00U3w00001GHJQVEA5'
    }
  }
];
