export const todo = require('./todo.png');
export const phone = require('./phone.png');
export const no = require('./no.png');
export const checkmark = require('./checkmark.png');
export const schedule = require('./schedule.png');
export const DL = require('./DL.png');
export const truck = require('./truck.png');
export const arrow = require('./arrow.png');
export const office = require('./office.png');
export const truck_30 = require('./truck_gray.png');
export const truck_orange = require('./truck_orange.png');
export const envelope = require('./envelope.png');
export const quit = require('./quit.png');
export const calendar = require('./calendar.png');
