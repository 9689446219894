import React from 'react';

import { ModalTitle, Subtitle } from './styles';

const ModalHeading = ({ title = '', subtitle = '' }) => {
  return (
    <>
      <ModalTitle>{title}</ModalTitle>

      <Subtitle> {subtitle} </Subtitle>
    </>
  );
};

export default ModalHeading;
