import { styled } from 'styled-components';

export const PageContainer = styled.div`
  margin-top: -40px;
`;

export const AvailableTimesContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
`;

export const Label = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
`;

export const Date = styled.div`
  font-size: 20px;
  color: orange;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

export const SlotsContainer = styled.div`
  height: 450px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${props => (props.isEmpty ? 'trasnparent' : '#eee')};
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f57b20;
    border-radius: 35px;
  }
`;

export const EmptyState = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
`;

export const Warning = styled.div`
  text-align: ${props => (props.color ? 'start' : 'center')};
  font-size: 19px;
  margin: ${props => (props.color ? '10px 0' : '15px 0')};
  color: ${props => props.color ?? '#f57b20'};
  font-weight: ${props => props.fontWeight ?? 'bold'};
`;

export const NoAvailability = styled.div`
  text-align: center;
  font-size: 19px;
  color: grey;
  font-weight: bold;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotaAvailableDateContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(245, 123, 32);
  border-radius: 20px;
  z-index: 99999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  padding: 20% 2%;
`;

export const QuitContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  height: 20px;
  width: 20px;
`;

export const Title = styled.div`
  color: white;
  font-size: 29px;
  text-align: center;
`;

export const Description = styled.div`
  color: white;
  font-size: 29px;
  text-align: center;
`;

export const SlotEmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SlotsWrapperContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 49% 49%;
  row-gap: 30px;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 30px;
`;

export const Divider = styled.div`
  width: 5px;
  background-color: rgb(245, 123, 32);
  margin: 0 5px;
`;

export const SlotTextContainer = styled.div`
   {
    text-align: center;
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    text-align: center;
  }
`;
