import { styled } from 'styled-components';

export const ConfirmOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfirmContainer = styled.div`
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  text-align: center;
`;

export const ConfirmHeader = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const ConfirmButton = styled.button`
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;

  &:first-of-type {
    background-color: #ccc;
    &:hover {
      background-color: #b7b5b5;
    }
    color: #000;
  }

  &:last-of-type {
    background-color: #dc5626;
    &:hover {
      background-color: #ff5a00;
    }
    color: #fff;
  }
`;
