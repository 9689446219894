import React, { useState, useRef, useContext } from 'react';
import { Context, useSave } from 'store';
import { Form } from 'form';
import moment from 'moment';

import config from '_src/services/driversLicense';
import { RoutesConfig } from '_src/modules/Router';
import FormHelper from '_src/components/FormHelper';
import ModalHeading from '_src/components/ModalHeading';
import { Button } from '_src/components/ModalButton';
import { updateContactConfig } from '_src/services/apiConfig';
import { ModalContainer, PageContainer, FormContainer } from './styles';

const DriversLicense = () => {
  const formApi = useRef(null);
  const { getData, setData } = useContext(Context);
  const { save: updateContact } = useSave(updateContactConfig);

  const { navigateTo, contact } = getData();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleNext = async () => {
    const formIsValid = formApi.current.validate();

    if (formIsValid) {
      setLoading(true);
      setError(null);
      const values = formApi.current.values();

      await updateContact({
        id: contact.id,
        ...values,
        licenseExpirationDate: moment.utc(values.licenseExpirationDate).set({ hour: 1 }),
        licenseIssuedDate: moment.utc(values.licenseIssuedDate).set({ hour: 1 })
      })
        .then(response => {
          if (response.code !== 204) {
            setError('Something went wrong!');
            return;
          }
          setLoading(false);
          setData('contact', { ...contact, ...response.data.contact });
          navigateTo(RoutesConfig.Age);
        })
        .catch(() => {
          setLoading(false);
          setError('Updating contact failed !');
        });
    }
  };

  return (
    <>
      <ModalHeading
        title="Drivers License"
        subtitle={
          'Please enter your license infromation: \n (We use this to verify your eligibility for a CDL)'
        }
      />

      <ModalContainer error={error}>
        <PageContainer>
          <FormContainer>
            <Form formApi={form => (formApi.current = form)} schema={config.form.schema}>
              <FormHelper fields={config.form.fields} />
            </Form>

            <Button label="Next" width="50%" onClick={handleNext} loading={loading} />
          </FormContainer>
        </PageContainer>
      </ModalContainer>
    </>
  );
};

export default DriversLicense;
