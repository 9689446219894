import React, { useState, useRef, useEffect, useCallback } from 'react';

import { Input, Value, Overlay, Container, Row, Label, Arrow, LabelAbove, Error } from './styles';
import FormInput from '../Input';
import { arrow } from '../../../assets/images';

export default ({
  value = '',
  hasError,
  onChange,
  label,
  labelAbove = '',
  items = [],
  labelKey = 'label',
  valueKey = 'value',
  disabled = false,
  searchable = false,
  name,
  error,
  width = '100%',
  'data-cy': cypressId
}) => {
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const input = useRef();
  const labelRef = useRef();
  const valueLabel = items.find(item => item[valueKey] === value);
  const hasValue = !!value;

  const handleClick = useCallback(e => {
    const isDropdownRow = e.target.classList.contains('dropdown-row');

    if (isDropdownRow || input.current.contains(e.target) || labelRef.current.contains(e.target)) {
      return;
    }

    closeDropdown();
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  const closeDropdown = () => {
    setSearch('');
    setOpen(false);
  };

  const getValue = () => {
    if (valueLabel) {
      return valueLabel[labelKey];
    }

    return error ? hasError : label;
  };

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const renderItems = items => {
    const searchText = search.toLowerCase();
    return (
      <>
        {items
          .filter(
            item =>
              item[valueKey].toLowerCase().includes(searchText) ||
              item[labelKey].toLowerCase().includes(searchText)
          )
          .map(item => {
            return (
              <Row
                key={item[valueKey]}
                className="dropdown-row"
                onClick={() => {
                  onChange(item[valueKey]);
                  closeDropdown();
                }}
              >
                {item[labelKey]}
              </Row>
            );
          })}
      </>
    );
  };

  return (
    <>
      {labelAbove && <LabelAbove hasError={hasError}>{labelAbove}</LabelAbove>}
      <Container data-cy={cypressId} width={width}>
        <Label
          ref={labelRef}
          hasError={hasError}
          hasValue={hasValue}
          isDisabled={disabled}
          onClick={() => (!disabled ? setOpen(!isOpen) : '')}
        >
          {hasError || label}
        </Label>

        {!disabled && <Arrow isOpen={isOpen} src={arrow} />}

        <div ref={input}>
          {!searchable && (
            <Input
              onClick={() => (!disabled ? setOpen(!isOpen) : '')}
              hasValue={hasValue}
              hasError={hasError}
              isOpen={isOpen}
              isDisabled={disabled}
              data-cy={cypressId + '-input'}
              name={name}
            >
              <Value>{getValue()}</Value>
            </Input>
          )}

          {searchable && (
            <FormInput
              name={name}
              data-cy={cypressId + '-input'}
              disabled={disabled}
              hasError={hasError}
              label={search ? '' : hasError || label}
              placeholder={isOpen ? getValue() : label}
              value={isOpen ? search : value ? getValue() : ''}
              onChange={handleSearch}
              onFocus={() => setOpen(true)}
              onBlur={() => closeDropdown()}
              style={isOpen ? { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 } : {}}
            />
          )}
        </div>

        <Overlay
          hasValue={hasValue}
          hasError={hasError}
          isOpen={isOpen}
          isDisabled={disabled}
          data-cy={cypressId + '-label'}
        >
          {renderItems(items)}
        </Overlay>

        {error && <Error>{error}</Error>}
      </Container>
    </>
  );
};
