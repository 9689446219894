import React from 'react';

import { OutlinedButton } from '_src/components/ModalButton';
import { Label } from './styles';

const ModalButton = ({ onClick, loading }) => {
  return (
    <>
      <Label>
        If none of the above times does not fit for you, you can try to choose another date
      </Label>

      <OutlinedButton
        label="I do not see a time here that works for me"
        width={'100%'}
        onClick={onClick}
        loading={loading}
      />
    </>
  );
};

export { ModalButton };
