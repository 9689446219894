import { styled } from 'styled-components';
import { ModalBody as Modal } from '../App/styles';
export const Container = styled.div`
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  position: fixed;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Avenir-Medium;
  left: 0;
  bottom: 0;
  font-size: 16px;
  color: #939393;
  width: 100%;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const PlainVersion = styled.div`
  font-family: Avenir-Medium;
  font-size: 16px;
  color: #939393;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 5px;
`;

export const ModalComponent = styled(Modal)`
  position: absolute;
  background-color: #ece6e0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999999;
  border-radius: 20px;
`;

export const CloseImage = styled.img`
  cursor: pointer;
  margin: 6px;
  padding: 6px;

  height: 20px;
  width: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const ModalBody = styled.div`
  max-height: 80vh;
  overflow: auto;
  padding: 25px 21px 0 21px;
  margin-top: 20px;
`;

export const ModalTitle = styled.div`
  font-family: Avenir-Medium;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  color: #151219;
  padding-bottom: 22px;
  border-bottom: 1px solid #d6d6d6;
`;

export const VersionItem = styled.div`
  width: 100%;
  padding-bottom: 35px;
  margin-top: 12px;
  border-bottom: 1px solid #d6d6d6;
  white-space: break-spaces;
`;

export const VersionItemDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const VersionNumberText = styled.div`
  font-family: Avenir-Medium;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: -0.33px;
  color: #151219;
`;

export const VersionDateText = styled.div`
  font-family: Avenir-Medium;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.33px;
  color: #636366;
`;

export const VersionNotesText = styled.div`
  font-family: Avenir-Medium;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.33px;
  color: #151219;
`;
