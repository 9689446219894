import React, { useState, useEffect, useContext } from 'react';
import { Context, useSave } from 'store';

import { MOCK_CONTACT } from '_src/services/mockData';
import { createContactConfig } from '_src/services/apiConfig';

import { RoutesConfig } from '_src/modules/Router';
import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { Button } from '_src/components/ModalButton';
import { todo, phone } from '_src/assets/images';
import { ButtonContainer, LoadingContainer } from './styles';
import { getTimezoneByZip, zipDetails } from '_src/utils/zipHelpers';
import { LoadingIndicator } from '_src/components/LoadingIndicator';

const PreScreen = () => {
  const { setData, getData } = useContext(Context);
  const { navigateTo } = getData();
  const { save: createContact } = useSave(createContactConfig);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [nextRouteQueue, setNextRouteQueue] = useState(null);

  const handleCreateContact = async () => {
    const initialContact = {
      first_name: localStorage.getItem('first_name'),
      last_name: localStorage.getItem('last_name'),
      email: localStorage.getItem('email'),
      phone: localStorage.getItem('phone'),
      zip: localStorage.getItem('zip'),
      state: localStorage.getItem('state'),
      sent_to_sf: false,
      create_in_sf: false
    };

    const timeZone = getTimezoneByZip(initialContact.zip);
    const zipCodeDetails = zipDetails(initialContact.zip);
    const { longitude, latitude } = zipCodeDetails;

    setError(null);
    setLoading(true);
    try {
      const response = await createContact({
        ...initialContact,
        contactTimeZone: timeZone,
        lat: latitude.toString(),
        lng: longitude.toString()
      });
      if (response.code !== 201) {
        setError('Something went wrong!');
        setLoading(false);
        return;
      }
      if (response.data.contact.defaultBranch) {
        setNextRouteQueue(RoutesConfig.ContactUs);
        setLoading(false);
        return;
      }
      setData('contact', {
        ...initialContact,
        ...response.data.contact
      });
      setLoading(false);
    } catch (error) {
      setError('Creating new contact failed !');
      setLoading(false);
    }
  };

  // if create contact api is still loading, save the next route as a queue
  // use the `useEffect` below to fire the `navigateTo` method after api call is finished
  const handleNextStepNavigation = nextRoute => {
    if (loading) {
      setNextRouteQueue(nextRoute);
      return;
    }
    navigateTo(nextRoute);
  };

  useEffect(() => {
    handleCreateContact();
  }, []);

  useEffect(() => {
    if (!loading && nextRouteQueue) {
      navigateTo(nextRouteQueue);
    }
  }, [loading, nextRouteQueue]);

  const subtitle = `Thanks for submitting your information!
Want to speed up your appointment process?
Simply answer a few quick questions to get started.`;

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIndicator color="#f57b20" height={100} width={100} />
      </LoadingContainer>
    );
  }

  return (
    <>
      <ModalHeading title="Expedite Your Appointment!" subtitle={subtitle} />

      <ModalBody error={error}>
        <ButtonContainer>
          <Button
            disabled={loading && nextRouteQueue}
            onClick={() => handleNextStepNavigation(RoutesConfig.VerifyLocation)}
            size="big"
            imageProps={{
              uri: todo,
              label: 'Lets Pre-Screen!',
              description:
                'Click here to answer a few quick questions and schedule an office visit.'
            }}
          />

          <Button
            disabled={loading && nextRouteQueue}
            size="big"
            onClick={() => handleNextStepNavigation(RoutesConfig.FinalStep)}
            imageProps={{
              uri: phone,
              label: 'No, thanks. Just Call Me.',
              description:
                'One of our enrollment specialists will reach out soon about starting your new career.'
            }}
          />
        </ButtonContainer>
      </ModalBody>
    </>
  );
};

export default PreScreen;
