import React from 'react';

import { LoadingSvg, LoadingCircle } from './styles';

const LoadingIndicator = ({ width, height, color }) => {
  return (
    <LoadingSvg width={width} height={height} viewBox="0 0 100 100">
      <LoadingCircle cx="50" cy="50" r="45" color={color} />
    </LoadingSvg>
  );
};

export { LoadingIndicator };
