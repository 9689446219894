import { styled } from 'styled-components';

export const ButtonContainer = styled.div`
  width: ${props => props.width ?? '40%'};
  padding: ${props => (props.size === 'small' ? '15px 0' : '30px 30px')};
  border-radius: 30px;
  background-color: ${props => (props.disabled ? '#A9A9A9' : '#f57b20')};
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.disabled ? '#A9A9A9' : '#ff5a00')};
  }
`;

export const Label = styled.div`
  font-size: 18px;
`;

export const Image = styled.img`
  height: 100px;
  width: 100px;
`;

export const ImageLabel = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
`;

export const ImageDescription = styled.div`
  text-align: center;
  margin-top: 15px;
`;

export const OutlinedButtonContainer = styled.div`
  width: ${props => props.width ?? '30%'};
  border-radius: 8px;
  padding: 10px 0;
  border: 1px solid #f57b20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const OutlinedLabel = styled.div`
  color: #f57b20;
`;
