import React, { useContext } from 'react';
import { Context } from 'store';

import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';

const FinalStep = () => {
  const { getData } = useContext(Context);
  const { finalScreenMessage } = getData();

  return (
    <>
      <ModalHeading title="Thank you!" subtitle={finalScreenMessage} />

      <ModalBody>
        <div />
      </ModalBody>
    </>
  );
};

export default FinalStep;
