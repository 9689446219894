import { styled } from 'styled-components';

const getColor = ({ hasError }, attribute) => {
  if (hasError) {
    return '#FF7B5C';
  }

  if (attribute === 'border') {
    return '#707070';
  }

  return '#F57B20';
};

const getFocusColor = ({ hasError }) => {
  return hasError ? '#FC4318' : '#1f1f1fa3';
};

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const TextareaContainer = styled.textarea`
  border: 1px solid ${props => getColor(props, 'border')};
  border-radius: 10px;
  padding: ${({ hasValue }) => (hasValue ? '16px 22px 6px 22px' : '10px 20px')};
  width: 100%;
  box-sizing: border-box;
  color: grey;
  font-size: 14px;
  resize: vertical;

  ::placeholder {
    color: lightgrey;
  }

  :hover,
  :focus {
    outline: none;
    border-color: ${getFocusColor};
    color: grey;
  }
`;
const Label = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 10px;
  position: absolute;
  left: 20px;
  top: ${({ hasValue }) => (hasValue ? 5 : 18)}px;
  opacity: ${({ hasValue }) => (hasValue ? 1 : 0)};
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
`;

const LabelAbove = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 16px;
  margin-bottom: 10px;
`;

export { Container, TextareaContainer, Label, LabelAbove };
