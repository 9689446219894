import React, { useContext, useState } from 'react';
import { Context, useSave } from 'store';

import { RoutesConfig } from '_src/modules/Router';
import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { Button } from '_src/components/ModalButton';
import { phone, office } from '_src/assets/images';
import { PageContainer } from './styles';
import { updateContactConfig, createEventConfig } from '_src/services/apiConfig';
import moment from 'moment';

const options = {
  'In-Person Only': [{ uri: office, label: 'In-Person', value: 'Meeting' }],
  'Phone Only': [{ uri: phone, label: 'Phone Call', value: 'Call' }],
  'In-Person or Phone acceptable': [
    { uri: phone, label: 'Phone Call', value: 'Call' },
    { uri: office, label: 'In-Person', value: 'Meeting' }
  ],
  'No Sits': []
};

const ScheduleVisit = () => {
  const { getData, setData } = useContext(Context);
  const { navigateTo, contact, selectedBranch, slotDetails } = getData();
  const { save: updateContact } = useSave(updateContactConfig);
  const { save: createNewEvent } = useSave(createEventConfig);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { appointmentType } = contact;

  const handleOptionSelect = async option => {
    const branch = selectedBranch;
    setError(null);
    setLoading(true);

    await updateContact({
      id: contact.id,
      accountId: branch.id,
      ownerId: branch.ownerId
    });

    createNewEvent({
      contactId: contact.id,
      appointmentType: option.value,
      appointmentDate: moment(slotDetails.appointmentDate).toISOString(),
      localTime: moment.utc(slotDetails.appointmentDate).local().format('MM-DD-YYYY HH:mm'),
      group_sit: Boolean(slotDetails.groupSitId),
      master_event_id: slotDetails.groupSitId ?? null
    })
      .then(res => {
        if (res.code === 429) {
          setLoading(false);
          setError('This slot is already booked. Please chosse another slot.');
          return;
        }

        if (res.code !== 201) {
          setLoading(false);
          setError('Something went wrong!');
          return;
        }

        setData('event', { ...res.data.event, location: branch });
        navigateTo(RoutesConfig.AppointmentDetails);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError('Something went wrong!');
      });
  };

  const renderButtons = () => {
    return (
      <PageContainer>
        {options[appointmentType].map((option, i) => {
          return (
            <Button
              key={i}
              onClick={() => handleOptionSelect(option)}
              size="big"
              loading={loading}
              imageProps={{
                uri: option.uri,
                label: option.label
              }}
            />
          );
        })}
      </PageContainer>
    );
  };

  return (
    <>
      <ModalHeading title="Schedule a Visit" subtitle="How would you like to meet?" />

      <ModalBody error={error}>{renderButtons()}</ModalBody>
    </>
  );
};

export default ScheduleVisit;
