import React from 'react';
import ReactDOM from 'react-dom';
import { ConfirmOverlay, ConfirmContainer, ConfirmHeader, ConfirmButton } from './styles';

const ConfirmDialog = ({ visible, onConfirm, onCancel }) => {
  const modalContainer = document.getElementById('modal-container');

  return (
    visible &&
    ReactDOM.createPortal(
      <ConfirmOverlay>
        <ConfirmContainer>
          <ConfirmHeader>Are you sure you want to quit?</ConfirmHeader>
          <ConfirmButton onClick={onConfirm}>Yes</ConfirmButton>
          <ConfirmButton onClick={onCancel}>No</ConfirmButton>
        </ConfirmContainer>
      </ConfirmOverlay>,
      modalContainer
    )
  );
};

export default ConfirmDialog;
