import React from 'react';

import { EmptyState, Warning } from './styles';

const EmptyStateData = ({ meetingDate }) => {
  if (!meetingDate) {
    return <EmptyState>Please select location and date!</EmptyState>;
  }

  return (
    <>
      <Warning fontWeight="normal">
        Below are some options around the date that you selected. Click below to pick a time slot!
      </Warning>

      <Warning>Please note that the time-slots shown here are in your local timezone!</Warning>
    </>
  );
};

export { EmptyStateData };
