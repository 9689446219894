import React, { useState } from 'react';

import { Container, Wrapper, PlainVersion } from './styles';
import { VersionModal } from './VersionModal';
import packageJson from '../../../package.json';

function VersionNumber({ plain = false }) {
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const openVersionModal = () => setIsVersionModalOpen(true);

  const closeVersionModal = () => setIsVersionModalOpen(false);

  if (plain) {
    return (
      <>
        <PlainVersion onClick={() => {}}>Version {packageJson.version}</PlainVersion>

        {isVersionModalOpen && (
          <VersionModal visible={isVersionModalOpen} onClose={closeVersionModal} />
        )}
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <Container onClick={openVersionModal}>
          Version {packageJson.version} <span style={{ marginLeft: '10px' }} />
        </Container>
      </Wrapper>

      <VersionModal visible={isVersionModalOpen} onClose={closeVersionModal} />
    </>
  );
}

export default VersionNumber;
