import React, { useRef } from 'react';

import { Container, TextareaContainer, Label, LabelAbove } from './styles';

export default ({
  value = '',
  error,
  label,
  touched,
  onChange,
  rows,
  labelAbove = '',
  name,
  ...rest
}) => {
  const hasError = touched && error;
  const hasValue = !!value;
  const textarea = useRef();

  return (
    <div>
      {labelAbove && <LabelAbove hasError={hasError}>{error || labelAbove}</LabelAbove>}

      <Container onClick={() => textarea.current.focus()}>
        <Label hasValue={hasValue} hasError={hasError}>
          {error || label}
        </Label>

        <TextareaContainer
          ref={textarea}
          placeholder={labelAbove ? label : error || label}
          rows={rows || 4}
          hasValue={hasValue}
          hasError={hasError}
          name={name}
          {...rest}
          onChange={e => onChange(e.target.value)}
        />
      </Container>
    </div>
  );
};
