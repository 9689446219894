import React, { useContext } from 'react';
import { Context } from 'store';

import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';
import { PageContainer, TextHelper, Image, DetailsContainer } from './styles';
import { envelope } from '_src/assets/images';

const AppointmentDetails = () => {
  const { getData } = useContext(Context);

  const { contact } = getData();

  const successText =
    contact?.appointmenType !== 'Call'
      ? `Please remember to bring your Drivers
              License with you, to your appointment.`
      : '';

  return (
    <>
      <ModalHeading
        title="Thank you!"
        subtitle={`You’re all set!  Please ensure you keep your appointment time as availability goes fast!  
Looking forward to meeting with you and getting you started on your new career!`}
      />

      <ModalBody>
        <PageContainer>
          <Image src={envelope} alt="evnelope" />

          <DetailsContainer>
            <TextHelper>
              You will receive a confirmation email and text shortly. {successText}
            </TextHelper>
          </DetailsContainer>
        </PageContainer>
      </ModalBody>
    </>
  );
};

export default AppointmentDetails;
