import { styled } from 'styled-components';

const Title = styled.div`
  color: #f57b20;
  font-size: 19px;
`;

const ModalTitle = styled(Title)`
  text-align: left;
  font-weight: bold;
  margin-bottom: 7px;
`;

const ModalBody = styled.div`
  display: grid;
  row-gap: 30px;
  grid-template-rows: 80% auto;
`;

const Info = styled.div`
  padding-bottom: 10px;
`;

const StyledButton = styled.button`
  text-align: center;
  color: #f57b20;
  font-size: 15px;
  user-select: none;
  background: transparent;
  border: 2px solid #f57b20;
  height: 35px;
  line-height: 35px;
  border-radius: 18px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
  min-width: 100px;
  outline: 0;
`;

const Content = styled.div`
  min-height: 100px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export { ModalBody, ModalTitle, Content, Info, StyledButton, ButtonsContainer };
