const moment = require('moment-timezone');

const userTimezone = moment.tz.guess();

const currentTime = moment().tz(userTimezone).utc();

const convertToUTC = (date, time, timeZone) => {
  return moment.tz(`${date}T${time.hour}:${time.minute}`, timeZone).utc().format();
};

const generateDateRangeExcludingWeekends = (startDate, endDate) => {
  const start = moment.utc(startDate).startOf('day');
  const end = moment.utc(endDate).startOf('day');

  return Array.from({ length: end.diff(start, 'days') + 1 }, (_, i) => start.clone().add(i, 'days'))
    .filter(date => date.isoWeekday() < 6)
    .map(date => date.format('YYYY-MM-DD'));
};

export function calculateFreeSlots({ from, to, duration, businessHours, busySlots }) {
  // Keep only normal busy slots without master group sit event
  const busySlotsWithoutGroupSits = busySlots.filter(({ IsMasterGroupSit_c, ActivityDate }) => {
    const isWeekend = moment.utc(ActivityDate).isoWeekday() >= 6;
    return !IsMasterGroupSit_c && !isWeekend;
  });

  // Only master group sit slots that has free sits available
  const masterGroupSlots = busySlots.filter(
    ({ IsMasterGroupSit_c, HasGroupSitOpening_c, ActivityDate }) => {
      const isWeekend = moment.utc(ActivityDate).isoWeekday() >= 6;

      return IsMasterGroupSit_c && HasGroupSitOpening_c && !isWeekend;
    }
  );

  // Calculate all possible free slots
  const allPossibleFreeSlots = () => {
    const dateRange = generateDateRangeExcludingWeekends(from, to);

    return dateRange.reduce((acc, date) => {
      const branchOpeningHour = convertToUTC(
        date,
        businessHours.opening,
        businessHours.branchTimeZone
      );
      const branchClosingHour = convertToUTC(
        date,
        businessHours.closing,
        businessHours.branchTimeZone
      );
      const start = moment.utc(branchOpeningHour);
      const end = moment.utc(branchClosingHour);

      const totalSlots = Math.floor(end.diff(start, 'minutes') / duration);

      acc[date] = Array.from({ length: totalSlots }, (_, i) => ({
        start: start
          .clone()
          .add(i * duration, 'minutes')
          .toISOString(),
        end: start
          .clone()
          .add((i + 1) * duration, 'minutes')
          .toISOString()
      }));

      return acc;
    }, {});
  };

  // Group busy slots in objects with array where as a key is used startDateTime date and
  // if any busy slots has included as endDateTime the next day, that slot is also included on next date array
  // where as a startDateTime has the next date and branch opening hour
  const groupedBusyEventsByStartDate = busySlotsWithoutGroupSits.reduce((acc, event) => {
    const { ActivityDate, StartDateTime, EndDateTime } = event;

    const eventStartDate = moment.utc(StartDateTime).format('YYYY-MM-DD');
    const eventEndDate = moment.utc(EndDateTime).format('YYYY-MM-DD');

    const openingHours = convertToUTC(
      ActivityDate,
      businessHours.opening,
      businessHours.branchTimeZone
    );
    const closingHours = convertToUTC(
      ActivityDate,
      businessHours.closing,
      businessHours.branchTimeZone
    );
    const isWeekend = moment.utc(eventEndDate).isoWeekday() >= 6;

    if (!acc[ActivityDate]) {
      acc[ActivityDate] = [];
    }

    // If the event spans multiple days, modify EndDateTime for the first occurrence
    if (eventStartDate !== eventEndDate) {
      event.EndDateTime = moment.utc(closingHours).toISOString();
    }

    if (!acc[eventStartDate].id) {
      acc[eventStartDate].push(event);
    }

    // If the event extends to another day, add a modified entry for the following date
    if (eventStartDate !== eventEndDate && !isWeekend) {
      if (!acc[eventEndDate]) {
        acc[eventEndDate] = [];
      }

      const nextDateOpeningHour = moment.utc(openingHours).add(1, 'day');

      const nextDayEvent = {
        ...event,
        StartDateTime: moment.utc(nextDateOpeningHour).toISOString() // Reset start time for the next day
      };

      acc[eventEndDate].push(nextDayEvent);
    }

    return acc;
  }, {});

  // From all possible free slots are removed busy slots
  const removedOverlappingSlots = () => {
    const freeSlots = allPossibleFreeSlots();
    const filteredFreeSlots = {};

    Object.keys(freeSlots).forEach(date => {
      if (!groupedBusyEventsByStartDate[date]) {
        // If there are no busy slots for this date, keep all free slots
        filteredFreeSlots[date] = freeSlots[date];
      } else {
        const busySlots = groupedBusyEventsByStartDate[date].map(event => ({
          start: moment.utc(event.StartDateTime),
          end: moment.utc(event.EndDateTime)
        }));

        filteredFreeSlots[date] = freeSlots[date].filter(slot => {
          const slotStart = moment.utc(slot.start);
          const slotEnd = moment.utc(slot.end);

          // Check if this slot overlaps with any busy slot
          return !busySlots.some(
            busy =>
              slotStart.isBetween(busy.start, busy.end, null, '[)') ||
              slotEnd.isBetween(busy.start, busy.end, null, '(]') ||
              (slotStart.isSameOrBefore(busy.start) && slotEnd.isSameOrAfter(busy.end))
          );
        });
      }
    });

    return filteredFreeSlots;
  };

  // If there is any available group sits then we should include those slots in free slots array
  // and remove free slots that are overlapping with it
  const applyGroupSitsSlots = () => {
    const updatedFreeSlots = removedOverlappingSlots();

    masterGroupSlots.forEach(slot => {
      const dateKey = slot.ActivityDate;
      const slotStart = moment.utc(slot.StartDateTime);
      const slotEnd = moment.utc(slot.EndDateTime);

      if (!updatedFreeSlots[dateKey]) {
        updatedFreeSlots[dateKey] = [];
      }

      // Remove overlapping free slots
      updatedFreeSlots[dateKey] = updatedFreeSlots[dateKey].filter(freeSlot => {
        const freeStart = moment.utc(freeSlot.start);
        const freeEnd = moment.utc(freeSlot.end);

        return !(
          freeStart.isBetween(slotStart, slotEnd, null, '[)') ||
          freeEnd.isBetween(slotStart, slotEnd, null, '(]') ||
          (freeStart.isSameOrBefore(slotStart) && freeEnd.isSameOrAfter(slotEnd))
        );
      });

      updatedFreeSlots[dateKey].push({
        start: slotStart.toISOString(),
        end: slotEnd.toISOString(),
        id: slot.Id
      });

      updatedFreeSlots[dateKey].sort((a, b) => moment.utc(a.start).diff(moment.utc(b.start)));
    });

    return updatedFreeSlots;
  };

  // Free slots are filtered with condition that those slots which have as a start in the past
  // should not be included, also for each date the free slots array is sorted
  const sortAndFilterFreeSlots = () => {
    const slots = applyGroupSitsSlots();
    const updatedSlots = {};

    Object.keys(slots).forEach(date => {
      const filteredAndSortedSlots = slots[date]
        .filter(slot => moment.utc(slot.start).isSameOrAfter(currentTime)) // Remove past slots
        .sort((a, b) => moment.utc(a.start).diff(moment.utc(b.start))); // Sort by start time

      if (filteredAndSortedSlots.length > 0) {
        updatedSlots[date] = filteredAndSortedSlots;
      }
    });

    return updatedSlots;
  };

  return sortAndFilterFreeSlots();
}
