import React from 'react';

import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';

const ContactUs = () => {
  return (
    <>
      <ModalHeading
        title="Thank you!"
        subtitle="Thank you for submitting your information! Please call (877) 350-0884 to complete your registration."
      />

      <ModalBody>
        <div />
      </ModalBody>
    </>
  );
};

export default ContactUs;
