import { styled } from 'styled-components';

const getColor = ({ hasError }, attribute) => {
  if (hasError) {
    return '#FF7B5C';
  }

  if (attribute === 'border') {
    return '#707070';
  }

  return 'grey';
};

const Input = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  transition: border-radius 0.5s;
  color: ${getColor};
  padding: ${({ hasValue }) => (hasValue ? '16px 20px 6px 20px' : '12px 20px')};
  border: 1px solid ${props => (props.hasError ? '#FF7B5C' : '#707070')};
  background-color: #fff;
  margin-bottom: 20px;
  ${({ isOpen }) =>
    isOpen &&
    `    
    border-bottom-color: transparent;
    border-radius: 17px 17px 0 0;
  `};
`;

const Value = styled.div``;

const Container = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : '100%')};
`;

const Overlay = styled.div`
  border: 1px solid ${props => getColor(props, 'border')};
  border-top: none;
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  background: #fff;
  border-bottom-right-radius: 34px;
  border-bottom-left-radius: 34px;
  max-height: 200px;
  overflow-y: scroll;
  transition: max-height 0.2s;
  z-index: 99999;

  ${({ isOpen }) =>
    !isOpen &&
    `max-height: 0;  
    border-bottom: none;
  `}
`;

const Row = styled.div`
  border-bottom: 1px solid rgba(112, 112, 112, 0.23);
  margin: 0 20px;
  padding: 6px 0;
  font-size: 12px;
  color: grey;
  cursor: pointer;
`;

const Label = styled.div`
  color: ${({ hasError }) => (hasError ? 'grey' : '#DC5626')};
  font-size: 10px;
  position: absolute;
  left: 20px;
  top: ${({ hasValue }) => (hasValue ? 5 : 18)}px;
  opacity: ${({ hasValue }) => (hasValue ? 1 : 0)};
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  height: 45px;
`;

const Arrow = styled.img`
  width: 10px;
  position: absolute;
  right: 20px;
  top: 21px;
  transition: transform 0.3s ease-in-out;
  ${({ isOpen }) => isOpen && 'transform: rotate(180deg);'}
`;

const LabelAbove = styled.div`
  color: ${({ hasError }) => (hasError ? '#FF7B5C' : '#F57B20')};
  font-size: 16px;
  margin-bottom: 10px;
`;

const Error = styled.div`
  color: #ff7b5c;
  font-size: 10px;
  margin-top: -15px;
`;

export { Input, Value, Container, Overlay, Row, Label, Arrow, LabelAbove, Error };
