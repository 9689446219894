import moment from 'moment';

export const generateFreeTimeSlots = (duration, busySlots, workTime, currentDate) => {
  const { start, close } = workTime.workingHours;

  const busyDateKeys = Object.keys(busySlots);

  const generateDateArray = currentDate => {
    const dates = [];
    let newDate = moment(currentDate);

    for (let i = 0; i < 6; i++) {
      dates.push(newDate.format('YYYY-MM-DD'));
      newDate = newDate.add(1, 'days');
    }

    return dates;
  };

  const currentDateKeys = busyDateKeys.includes(currentDate)
    ? busyDateKeys
    : [...generateDateArray(currentDate), ...busyDateKeys];

  let slots = {};

  const removeBusySlots = (slots, busySlots) => {
    const result = {};
    const now = moment().utc();

    Object.keys(slots).forEach(dateKey => {
      const dayOfWeek = moment(dateKey).day();

      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return;
      }

      if (busySlots[dateKey]) {
        const filteredSlots = slots[dateKey].filter(firstSlot => {
          const firstStart = moment(firstSlot.start);
          const firstEnd = moment(firstSlot.end);

          if (firstStart.isBefore(now)) {
            return false;
          }

          return !busySlots[dateKey].some(secondSlot => {
            const secondStart = moment(secondSlot.ActivityDateTime || secondSlot.start);
            const secondEnd = moment(secondSlot.EndDateTime || secondSlot.end);

            return (
              firstStart.isSame(secondStart) ||
              firstEnd.isSame(secondEnd) ||
              firstStart.isBetween(secondStart, secondEnd, null, '[)') ||
              firstEnd.isBetween(secondStart, secondEnd, null, '(]') ||
              secondStart.isBetween(firstStart, firstEnd, null, '[)') ||
              secondEnd.isBetween(firstStart, firstEnd, null, '(]')
            );
          });
        });

        if (filteredSlots.length > 0) {
          result[dateKey] = filteredSlots;
        }
      } else {
        const filteredSlots = slots[dateKey].filter(slot => now.isBefore(moment(slot.start)));
        if (filteredSlots.length > 0) {
          result[dateKey] = filteredSlots;
        }
      }
    });

    return result;
  };

  const calculateWorktime = date => {
    let openingHoursTimeStamp = moment(start, 'YYYY-MM-DD HH:mm:ss');
    let closingHoursTimeStamp = moment(close, 'YYYY-MM-DD HH:mm:ss');

    const offset1 = moment(date).utcOffset();
    const offset2 = openingHoursTimeStamp.utcOffset();
    const offsetDiff = offset2 - offset1;

    if (offsetDiff > 0) {
      openingHoursTimeStamp.add(1, 'hour');
      closingHoursTimeStamp.add(1, 'hour');
    }

    if (offsetDiff < 0) {
      openingHoursTimeStamp.subtract(1, 'hour');
      closingHoursTimeStamp.subtract(1, 'hour');
    }

    const formatedOpen = openingHoursTimeStamp.format('YYYY-MM-DD HH:mm:ss');
    const formatedClose = closingHoursTimeStamp.format('YYYY-MM-DD HH:mm:ss');

    const currentSlotDate = moment(date).date();
    const currentSlotMonth = moment(date).month();
    const currentSlotYear = moment(date).year();

    let openingHours = moment
      .utc(formatedOpen)
      .year(currentSlotYear)
      .month(currentSlotMonth)
      .date(currentSlotDate);
    let closingHours = moment
      .utc(formatedClose)
      .year(currentSlotYear)
      .month(currentSlotMonth)
      .date(currentSlotDate);

    if (closingHours.isBefore(openingHours)) {
      closingHours = moment.utc(closingHours).date(currentSlotDate + 1);
    }

    return { openingHours, closingHours };
  };

  const addNewSlots = date => {
    const { openingHours, closingHours } = calculateWorktime(date);

    if (slots[date]) {
      const currentDateSlotsLength = slots[date].length;
      const { end } = slots[date][currentDateSlotsLength - 1];

      const startOfNewSlot = moment.utc(end);
      const endOfNewSlot = moment.utc(end).add(duration, 'minutes');

      if (endOfNewSlot.isAfter(closingHours)) {
        slots = removeBusySlots(slots, busySlots);
        return;
      }

      slots[date] = [
        ...slots[date],
        {
          start: startOfNewSlot.format(),
          end: endOfNewSlot.format()
        }
      ];

      addNewSlots(date);
    } else {
      slots[date] = [
        {
          start: moment.utc(openingHours).format(),
          end: moment.utc(openingHours).add(duration, 'minutes').format()
        }
      ];

      addNewSlots(date);
    }
  };

  currentDateKeys.forEach(date => addNewSlots(date));
  return removeBusySlots(slots, busySlots);
};
