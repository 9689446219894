import { styled } from 'styled-components';

export const LoadingSvg = styled.svg`
  width: ${props => props.width ?? '20px'};
  height: ${props => props.height ?? '20px'};
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  animation: spin 1s linear infinite;

  @keyframes draw {
    0% {
      stroke-dasharray: 20, 282.6;
    }
    50% {
      stroke-dasharray: 200, 282.6;
    }
    100% {
      stroke-dasharray: 20, 282.6;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingCircle = styled.circle`
  fill: none;
  stroke: ${props => props.color ?? '#ffff'};
  stroke-linecap: round;
  stroke-width: 4;
  animation: draw 3s ease-in-out infinite;
`;
