export default [
  {
    versionNumber: '1.1.1',
    versionDate: 'Jan 6, 2025',
    notes: [
      'Public Calendar',
      'Group Sits ',
      'Limit creation of event to 22 days after current date.'
    ]
  },
  {
    versionNumber: '1.0.1',
    versionDate: 'Oct 10, 2024',
    notes: ['Required changes and bug fixes.']
  },
  {
    versionNumber: '1.0.0',
    versionDate: 'Aug 22, 2024',
    notes: ['First Widget release.']
  }
];
