import { styled } from 'styled-components';

import ModalBody from '_src/components/ModalBody';

export const ModalContainer = styled(ModalBody)`
  padding-top: 30px;
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const FormContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
