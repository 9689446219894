// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global.css */

/* Import the font from Google Fonts */

/* Apply the font globally */
body {
    font-family: 'Source Sans Pro', sans-serif;
}

/* Ensure all elements inherit this font */
*,
*::before,
*::after {
    font-family: inherit;
    box-sizing: inherit;
}`, "",{"version":3,"sources":["webpack://./src/modules/App/global.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf,sCAAsC;;AAGtC,4BAA4B;AAC5B;IACI,0CAA0C;AAC9C;;AAEA,0CAA0C;AAC1C;;;IAGI,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":["/* global.css */\n\n/* Import the font from Google Fonts */\n@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');\n\n/* Apply the font globally */\nbody {\n    font-family: 'Source Sans Pro', sans-serif;\n}\n\n/* Ensure all elements inherit this font */\n*,\n*::before,\n*::after {\n    font-family: inherit;\n    box-sizing: inherit;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
