import { deepMapKeys, clone } from 'utils';
import moment from 'moment';

import { snakeToCamelCase, camelToSnakeCase } from './helpers';

const objToCamelCase = obj => deepMapKeys(obj, key => snakeToCamelCase(key));

const objToSnakeCase = obj => deepMapKeys(obj, key => camelToSnakeCase(key));

const formatDate = (date, format) => {
  let localDate = moment.utc(date).local();
  localDate = format ? localDate.format(format) : localDate;

  return date ? localDate : '';
};

const parseAddress = results => {
  const address = objToCamelCase(results[0]);
  let parsed = {};

  address.addressComponents.forEach(({ longName, shortName, types }) => {
    if (types.includes('country')) {
      parsed.country = shortName;
    }

    if (types.includes('postal_code')) {
      parsed.postalCode = longName;
    }

    if (types.includes('route')) {
      parsed.street += longName;
    }

    if (types.includes('street_number')) {
      parsed.street = longName + ', ' + (parsed.street || '');
    }

    if (types.includes('administrative_area_level_1')) {
      parsed.state = shortName;
    }

    if (types.includes('locality')) {
      parsed.city = longName;
    }
  });

  return parsed;
};

const stripNullObj = obj => {
  let stripped = clone(obj);

  Object.keys(obj).forEach(key => {
    if (stripped[key] === null) {
      delete stripped[key];
    }
  });

  return stripped;
};

const dateToUnix = date => {
  return date === null ? null : typeof date === 'string' ? moment(date).unix() : date.unix();
};

const isEmail = email => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

const isUrl = (url = '') => {
  const regex = new RegExp(
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
  );

  return regex.test(url);
};

const textInputValueWithoutEmoji = (value = '') => {
  const regex =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\uD80C[\uDC00-\uDFFF]|\uD80D[\uDC00-\uDC2E])/g;
  return value.replace(regex, '');
};

const isEmptyObj = object => {
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    const callNow = immediate && !timeout;

    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

const filterObjectEmptyValues = (obj = {}) => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] !== 'number' && (!obj[key] || !obj[key].length)) {
      delete obj[key];
    }
  });

  return obj;
};

export {
  objToCamelCase,
  objToSnakeCase,
  formatDate,
  parseAddress,
  stripNullObj,
  dateToUnix,
  isEmail,
  isUrl,
  isEmptyObj,
  debounce,
  filterObjectEmptyValues,
  textInputValueWithoutEmoji
};
