const yup = require('yup');
const moment = require('moment');

const schema = yup.object().shape({
  issueState: yup.string().nonNullable().required('State is required!'),
  licenseIssuedDate: yup
    .string()
    .nonNullable()
    .required('Issue Date is required')
    .test('is-valid-date', 'Issue Date must be a valid date', function (value) {
      return moment(value, 'YYYY-MM-DD', true).isValid();
    })
    .test(
      'is-before-or-equal-current-date',
      'Issue Date cannot be after current date',
      function (value) {
        return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment(), 'day');
      }
    ),
  licenseExpirationDate: yup
    .string()
    .nonNullable()
    .required('Expire Date is required!')
    .test('is-valid-date', 'Expire Date must be a valid date', function (value) {
      return moment(value, 'YYYY-MM-DD', true).isValid();
    })
    .test('is-before-issue-date', 'Expire Date must be after Issue Date', function (value) {
      const { licenseIssuedDate } = this.parent;
      return moment(value, 'YYYY-MM-DD').isAfter(moment(licenseIssuedDate, 'YYYY-MM-DD'));
    })
    .test('is-before-current-date', 'Expire Date must be after current date', function (value) {
      return moment(value, 'YYYY-MM-DD').isAfter(moment());
    })
});

module.exports = schema;
