import React, { useMemo } from 'react';

import { Button } from '_src/components/ModalButton';
import { weekDaysLabel } from './constants';
import {
  NoAvailability,
  SlotEmptyStateContainer,
  SlotsWrapperContainer,
  Divider,
  SlotTextContainer,
  Date
} from './styles';
import moment from 'moment';

const NoAvailableSlots = ({ date }) => {
  const dayLabel = weekDaysLabel[moment(date).day()];

  return (
    <SlotEmptyStateContainer>
      <Date>
        Date: {moment(date).format('MM-DD-YYYY')} - {dayLabel}
      </Date>

      <NoAvailability>No available slots on this date.</NoAvailability>
    </SlotEmptyStateContainer>
  );
};

const SlotContainer = ({ start, end, id, type, loading, createEvent }) => {
  const localStart = moment.utc(start).local().format('hh:mm A');
  const localEnd = moment.utc(end).local().format('hh:mm A');
  const eventType = type === 'Phone' ? 'Call' : 'Meeting';

  return (
    <Button
      width="100%"
      style={{ height: 'fit-content', textAlign: 'center' }}
      onClick={() => {
        createEvent(start, id, eventType);
      }}
      size="small"
      label={
        <SlotTextContainer>
          <div>{type}</div>
          {localStart} - {localEnd}
        </SlotTextContainer>
      }
      disabled={loading}
    />
  );
};

const FreeSlotsSection = ({ availableHours, loading, createEvent, selectedBranchData }) => {
  if (!selectedBranchData?.id) return null;

  const { personSit, phoneSit, groupSit } = selectedBranchData;
  const showBothAppointmentTypeSlots = (personSit && phoneSit) || (phoneSit && groupSit);

  const showOnlyPhoneSits = phoneSit && !personSit && !groupSit;
  const showOnlyInPersonSits = personSit && !groupSit && !phoneSit;
  const showOnlyGroupSits = groupSit && !phoneSit && !personSit;

  const showOnlyPhoneAndInPersonSits = phoneSit && personSit && !groupSit;
  const showOnlyGroupAndInPersonSits = groupSit && personSit && !phoneSit;
  const showOnlyPhoneAndGroupSits = phoneSit && groupSit && !personSit;

  const showAll = phoneSit && personSit && groupSit;

  const slots = useMemo(() => {
    if (showAll) {
      return {
        inPerson: availableHours,
        phone: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => !id)
          ])
        )
      };
    }

    if (showOnlyPhoneSits) {
      return {
        inPerson: Object.keys(availableHours).reduce((acc, date) => {
          acc[date] = [];
          return acc;
        }, {}),
        phone: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => !id)
          ])
        )
      };
    }

    if (showOnlyInPersonSits) {
      return {
        inPerson: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => !id)
          ])
        ),
        phone: Object.keys(availableHours).reduce((acc, date) => {
          acc[date] = [];
          return acc;
        }, {})
      };
    }

    if (showOnlyGroupSits) {
      return {
        inPerson: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => id)
          ])
        ),
        phone: Object.keys(availableHours).reduce((acc, date) => {
          acc[date] = [];
          return acc;
        }, {})
      };
    }

    if (showOnlyPhoneAndInPersonSits) {
      return {
        inPerson: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => !id)
          ])
        ),
        phone: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => !id)
          ])
        )
      };
    }

    if (showOnlyGroupAndInPersonSits) {
      return {
        inPerson: availableHours,
        phone: Object.keys(availableHours).reduce((acc, date) => {
          acc[date] = [];
          return acc;
        }, {})
      };
    }

    if (showOnlyPhoneAndGroupSits) {
      return {
        inPerson: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => id)
          ])
        ),
        phone: Object.fromEntries(
          Object.entries(availableHours).map(([date, hours]) => [
            date,
            hours.filter(({ id }) => !id)
          ])
        )
      };
    }

    return {
      inPerson: {},
      phone: {}
    };
  }, [
    availableHours,
    showAll,
    showOnlyPhoneSits,
    showOnlyInPersonSits,
    showOnlyGroupSits,
    showOnlyPhoneAndInPersonSits,
    showOnlyGroupAndInPersonSits,
    showOnlyPhoneAndGroupSits
  ]);

  return Object.entries(availableHours).map(([date]) => {
    const dayLabel = weekDaysLabel[moment(date).day()];
    const hasNoSlots = !slots.phone[date]?.length && !slots.inPerson[date]?.length;

    if (hasNoSlots) return <NoAvailableSlots key={date} date={date} />;

    return (
      <div key={date}>
        <Date>
          Date: {moment(date).format('MM-DD-YYYY')} - {dayLabel}
        </Date>

        <div style={{ display: 'flex', width: '100%' }}>
          {slots?.inPerson?.[date]?.length > 0 && (
            <SlotsWrapperContainer>
              {slots?.inPerson[date]?.map(({ id, start, end }) => (
                <SlotContainer
                  key={`${start}${end}`}
                  start={start}
                  end={end}
                  id={id}
                  type="In Person"
                  loading={loading}
                  createEvent={createEvent}
                />
              ))}
            </SlotsWrapperContainer>
          )}

          {showBothAppointmentTypeSlots && <Divider />}

          {phoneSit && slots?.phone[date]?.length > 0 && (
            <SlotsWrapperContainer>
              {slots?.phone[date].map(({ id, start, end }) => (
                <SlotContainer
                  key={`${start}${end}`}
                  start={start}
                  end={end}
                  id={id}
                  type="Phone"
                  loading={loading}
                  createEvent={createEvent}
                />
              ))}
            </SlotsWrapperContainer>
          )}
        </div>
      </div>
    );
  });
};

export { FreeSlotsSection };
