import React from 'react';

import { LoadingIndicator } from '../LoadingIndicator';
import {
  ButtonContainer,
  Label,
  Image,
  ImageLabel,
  ImageDescription,
  OutlinedButtonContainer,
  OutlinedLabel
} from './styles';

const Button = ({ onClick, label, size = 'small', width, imageProps, loading, disabled }) => {
  const isDisabled = loading || disabled;

  return (
    <ButtonContainer
      onClick={isDisabled ? () => {} : onClick}
      size={size}
      width={width}
      disabled={isDisabled}
    >
      {size === 'small' ? (
        loading ? (
          <LoadingIndicator color="#f57b20" />
        ) : (
          <Label>{label}</Label>
        )
      ) : (
        <>
          <Image src={imageProps?.uri} />
          <ImageLabel>{imageProps?.label}</ImageLabel>
          <ImageDescription>{imageProps?.description}</ImageDescription>
        </>
      )}
    </ButtonContainer>
  );
};

const OutlinedButton = ({ onClick, label, width, loading, disabled }) => {
  const isDisabled = loading || disabled;
  return (
    <OutlinedButtonContainer
      onClick={isDisabled ? () => {} : onClick}
      width={width}
      disabled={isDisabled}
    >
      {loading ? <LoadingIndicator color="#f57b20" /> : <OutlinedLabel>{label}</OutlinedLabel>}
    </OutlinedButtonContainer>
  );
};

export { Button, OutlinedButton };
