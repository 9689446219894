const meetingOptions = {
  'In-Person Only': 'Meeting',
  'Phone Only': 'Call'
};

const weekDaysLabel = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const durationMap = {
  '15 MINUTES': 15,
  '30 MINUTES': 30,
  '45 MINUTES': 45,
  '1 HOUR': 60
};

export { meetingOptions, weekDaysLabel, durationMap };
