import { styled } from 'styled-components';

export const ModalContent = styled.div`
  min-height: 150px;
  padding-top: 50px;
`;

export const ErrorContainer = styled.div`
  background-color: rgb(245, 123, 32, 0.4);
  font-size: 20px;
  text-align: center;
  padding: 10px 0;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  border: 1px solid #f57b20;
`;
