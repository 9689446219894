import React from 'react';

import ModalHeading from '_src/components/ModalHeading';
import ModalBody from '_src/components/ModalBody';

const FinalStep = () => {
  return (
    <>
      <ModalHeading
        title="Thank you!"
        subtitle="Thank you for submitting your information, one of our enrollment specialists will call you soon about starting your new career in trucking!"
      />

      <ModalBody>
        <div />
      </ModalBody>
    </>
  );
};

export default FinalStep;
