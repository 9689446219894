import { styled } from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(113, 113, 113, 0.9);
  overflow-y: scroll;
`;

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 40px #0000003b;
  width: 40%;
  min-width: 530px;
  margin: 60px auto 30px auto;
  border-radius: 35px;
  padding: 25px;
  position: relative;

  @media screen and (max-width: 420px) {
    min-width: 360px;
  }
`;

const Close = styled.div`
  color: #dc5626;
  font-size: 30px;
  position: absolute;
  right: 25px;
  top: 10px;
  cursor: pointer;
`;

export const Image = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export { Container, Overlay, Close };
