import React, { useEffect, useCallback, useState } from 'react';
import { Provider, State } from 'store';

import { PersistentRepo } from '_src/shared/repo';
import { objToCamelCase, objToSnakeCase } from '_src/utils';
import Router, { RoutesConfig } from '_src/modules/Router';
import { Modal } from '_src/components';
import './global.css';
import VersionNumber from '../VersionNumber';

let app = document.getElementById('160-root');
let modalContainer = document.getElementById('modal-container');

if (!app) {
  app = document.createElement('div');
  app.id = '160-root';
  document.body.appendChild(app);
}

if (!modalContainer) {
  modalContainer = document.createElement('div');
  modalContainer.id = 'modal-container';
  document.body.appendChild(modalContainer);
}

const sersionNumberStyles = {
  marginRight: '16px',
  textAlign: 'right',
  color: ' rgba(0, 0, 0, 0.65)',
  fontSize: '12px',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  fontVariant: 'tabular-nums',
  lineHeight: 1.5
};

const App = () => {
  const [showModal, setShowModal] = useState(true);
  const token = PersistentRepo.get('token');
  const privileges = PersistentRepo.get('privileges');
  const user = PersistentRepo.get('user');

  const registerButton = document.getElementById('btn-quote-submit');

  const displayModal = useCallback(() => {
    document.body.style.height = '100vh';
  }, []);

  const closeModal = () => {
    setShowModal(false);
    document.body.style.removeProperty('height');
  };

  const defaultState = {
    authenticated: !!token,
    token,
    privileges,
    attendance: [],
    user,
    activeRoute: RoutesConfig.PreScreen,
    navigateTo: () => {},
    closeModal
  };

  const handleResponses = data => {
    if (token && data.code === 401) {
      PersistentRepo.clear();
      window.location.reload();
    }

    if (process.env.NODE_ENV === 'production') {
      const getUrl = window.location.href.split('/')[2];
      const frontEndUrl = `https://${getUrl}`;

      fetch(`${frontEndUrl}/manifest.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        cache: 'reload'
      })
        .then(response => {
          return response.json();
        })
        .catch(error => console.log(error));
    }

    return objToCamelCase(data);
  };

  useEffect(() => {
    registerButton && registerButton.addEventListener('click', displayModal);

    return () => registerButton && registerButton.removeEventListener('click', displayModal);
  }, [registerButton, displayModal]);

  return (
    <div>
      <Provider
        apiUrl={GLOBALS.BASE_API}
        data={defaultState}
        headers={({ token }) => ({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        })}
        beforeGet={objToSnakeCase}
        afterGet={handleResponses}
        beforeSave={objToSnakeCase}
        afterSave={handleResponses}
      >
        <State name="privileges">
          {() => {
            return (
              <Modal visible={showModal} onClose={closeModal}>
                <Router />

                <VersionNumber plain light style={sersionNumberStyles} />
              </Modal>
            );
          }}
        </State>
      </Provider>
    </div>
  );
};

export default App;
